import dagreD3 from "dagre-d3";

function parse(wf) {

  const g = new dagreD3.graphlib.Graph().setGraph({});

  // Nodes
  wf.Nodes.forEach(n => {
    const incprops = { rx: 5, ry: 5, class: n.Type };

    // Round start + stop nodes
    if (["StartNode","StopNode"].includes(n.Type)) {
      incprops.rx = incprops.ry = 50;
      incprops.label = n.Type;
    }

    g.setNode(n.Id, {
      labelType: "html",
      label: `<div class="type">${n.Type}</div><div class="name">${n.Name}</div>`, 
      tooltip: `<div><strong>Properties</strong></div><pre>${JSON.stringify(n.Properties, false, 2)}</pre>`,
      ...incprops
    });
  });

  // Edges
  wf.Nodes.forEach(n => {
      n.Edges.forEach(e => {
        let props = {};

        if (e.Connector !== "Out") {
            props = { ...props, label: e.Connector };
        }

        g.setEdge(n.Id, e.ToNode, props);
      });
  });

  return g;
}

export default parse;
