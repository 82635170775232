import { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import dagreD3 from "dagre-d3";
import tippy from "tippy.js";
import graphGenerator from "./graph-generator";
import defaultWorkflowCode from "./default-wf.json";
import "./App.css";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";

function App() {

  const [code, setCode] = useState(JSON.stringify(defaultWorkflowCode));

  const svgref = useRef(null);

  useEffect(() => {
    
    let g;

    try
    {
      g = graphGenerator(JSON.parse(code));  
    }
    catch(ex)
    {
      console.error(ex);
      return;
    }

    // Create a fresh group to render in
    const svg = d3.select(svgref.current);
    svg.selectAll("*").remove();
    const svgGroup = svg.append("g");
    
    // zoom function
    const zoom = d3.zoom().on("zoom", () => svgGroup.attr("transform", d3.event.transform));
    svg.call(zoom);

    // Run the renderer. This is what draws the final graph.
    new dagreD3.render()(svgGroup, g);

    // center 
    svg.call(zoom.transform, d3.zoomIdentity.translate((svg.attr("width") - g.graph().width * 1) / 2, 20).scale(1));

    // tooltips
    svgGroup.selectAll("g.node")
      .each(nid => {
        const node = g.node(nid);
        tippy(node.elem, { 
          placement: "right",
          theme: "light-border",
          maxWidth: 700,
          allowHTML: true,
          content: node.tooltip
        });
      });

  }, [code]);

  return (
    <div>
      <header className="header">
        <div className="header-title">
          Gnatta Workflow Visualisation
        </div>
        <div className="header-subtext">
          Temporary assistance tool, not part of the system
        </div>
      </header>
      <div className="input-wrapper">
        <p>Paste exported workflow json here, graph will render below:</p>
        <textarea className="input-area"
          value={code} onChange={ev => setCode(ev.target.value)}>
        </textarea>
      </div>
      <div className="svg-wrapper">
        <svg ref={svgref} width="1400" height="600"></svg>
      </div>
    </div>
  );
}

export default App;
